import React from "react"
import { Link } from "gatsby"
import PostMeta from "./PostMeta"
import PostCategories from "./PostCategories"
import FeaturedMedia from "./FeaturedMedia"

const FeaturedPostPreview = ({ post }) => {
  return (
    <>
      {post ? (

        <article
          className={`featured-post post-${post.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
          id={`post-${post.databaseId}`}
        >
          <div className="image">
            {post.tags.nodes.length ? (
              <div className="tags">{post.tags.nodes[0].name}</div>
            ) : (
              ""
            )}

            <Link to={post.uri}>
              <img src={post?.featuredImage?.node?.sourceUrl} />
            </Link>
          </div>

          <div className="post-inner">
            <div className="show-mobile">
              <div className="post-meta">
                <div className="date">{post.date}</div>
                <PostCategories categories={post.categories} />
                <div className="author">
                  by <span>{post.author.node.name}</span>
                </div>
              </div>
            </div>

            <div className="breadcrumb show-desktop">
              <Link to="/community">Community</Link>
              <div className="arrow"></div>
              <span>Featured</span>
            </div>

            <h2>
              <Link
                to={post.uri}
                dangerouslySetInnerHTML={{ __html: post.title }}
              />
            </h2>

            <div className="content">
              <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </div>
          </div>
        </article>
      ) : ''}
    </>
  )
}

export default FeaturedPostPreview
