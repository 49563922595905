import React from "react"
import { Link } from "gatsby"
import PostMeta from "./PostMeta"
import PostCategories from "./PostCategories"
import FeaturedMedia from "./FeaturedMedia"

const PostPreview = ({ post, isLast }) => {
  return (
    <>
      {post ? (
        <article
          className={`post-${post.databaseId ? post.databaseId : 'preview'} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
          id={`post-${post.databaseId ? post.databaseId : 'preview'}`}
        >
          <div className="image">
            <Link to={post.uri}>
              <FeaturedMedia image={post.featuredImage} />
            </Link>
          </div>

          <div className="post-inner">
		  
		              <h2>
              <Link
                to={post.uri}
                dangerouslySetInnerHTML={{ __html: post.title }}
              />
            </h2>
            <div className="post-meta">
              <div className="date">{post.date}</div>
				  {/*<PostCategories categories={post.categories} />*/}
              <div className="author">
                by <span>{post.author.node.name}</span>
              </div>
            </div>

			  <div className="show-mobile">
			<br></br>
				</div>

			
            <div className="show-desktop">
			{/*
              <PostCategories categories={post.categories} />
			  */}
			  <br></br>
            </div>
			
            <div className="content">
              <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
			  <div className="show-mobile">
			<br></br>
				</div>
            </div>
          </div>
        </article>
      ) : ''}
    </>
  )
}

export default PostPreview
