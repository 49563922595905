import React, { useState, useLayoutEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import PostEntry from "../../components/post-entry"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

// import SEO from "../../components/seo"
// import NextIcon from "../../components/icons/next"
// import PreviousIcon from "../../components/icons/previous"
import FeaturedPostPreview from "../components/FeaturedPostPreview"
import PostPreview from "../components/PostPreview"
import ArchivePagination from "../components/ArchivePagination"
import PageHeader from "../components/PageHeader"
import Sidebar from "../components/Sidebar"
import Instagram from "../components/Instagram"
import BlogHeader from "../components/BlogHeader"

import background from "../assets/images/header@2x.jpg"
import Search from "../assets/svg/lens.svg"
import Filter from "../assets/svg/filter.svg"

import Form from "../components/Form"

const Archive = (props) => {
  const {
    data: {
      allWpPost: { nodes, pageInfo },
      wp: { acfOptionsOptions },
    },
    pageContext: { archiveType, archivePath },
  } = props

  const featured = props.data.wpPost

  const [state, setState] = useState({
    showSearch: false,
    showFilters: false,
  })

  const ToggleFilters = (type) => {
    let showSearch, showFilter

    if (type === "search") {
      showSearch = state.showSearch ? false : true
      showFilter = false
    } else {
      showSearch = false
      showFilter = state.showFilter ? false : true
    }

    setState({
      showSearch,
      showFilter,
    })
  }

  const [load, setLoad] = useState({
    items: 4,
    show: true,
  })

  const LoadMore = () => {
    setLoad({
      items: 9999,
      show: false,
    })
  }

  const allPosts = props.data.allWpPost.nodes
  const emptyQuery = ""

  const [q, setQ] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const [category, setCategory] = useState(0)

  const FilterCategory = (event) => {
    let category = ""

    if (event.target || event.currentTarget) {
      category = event.target.value
        ? event.target.value
        : event.currentTarget.innerText.toLowerCase()
    } else {
      category = event
    }

    setCategory(category)
    setSearch("")

    const posts = props.data.allWpPost.nodes || []

    const filteredData = posts.filter((post) => {
      const { categories } = post

      return categories.nodes.some((cat) => {
        return cat.slug.includes(category)
      })
    })

    setQ({
      category,
      filteredData,
    })

    LoadMore()
  }

  const [search, setSearch] = useState("")

  const FilterSearch = (event) => {
    let search = ""

    if (typeof event === "object") {
      search = event.target.value
    } else {
      search = event
    }

    setSearch(search)
    setCategory(0)

    const posts = props.data.allWpPost.nodes || []

    const filteredData = posts.filter((post) => {
      const { excerpt, title } = post

      return (
        excerpt.toLowerCase().includes(search.toLowerCase()) ||
        title.toLowerCase().includes(search.toLowerCase())
      )
    })

    setQ({
      search,
      filteredData,
    })

    LoadMore()
  }

  const { filteredData, query } = q
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  useLayoutEffect(() => {
    const query = new URLSearchParams(props.location.search)
    const category = query.get("c")
    const search = query.get("s")

    if (category) {
      FilterCategory(category)
    }

    if (search) {
      FilterSearch(search)
    }
  }, [])

  const [popup, setPopup] = useState(false)

  const handlePopup = (event) => {
    event.preventDefault();
    setPopup(!popup)
  }

  return (
    <Layout
      wp={{ options: {} }}
      bodyClass="home blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support"
    >
      <Seo data={{}} />

      <BlogHeader />

      <section className="featured-post-wrapper">
        <div className="container">
          <FeaturedPostPreview post={featured} />
        </div>
      </section>

      <div className="show-mobile">
		<div className="sidebarpost-mobile">
			<p>
				Have a forest you are really proud of? Tell us all about it
			</p>
			<div className="button-holder">
				<a href="#" type="button" className="button" onClick={handlePopup}>Share Your Forest!</a>
			</div>
		</div>
        <section className="blog-search">
          <div className="search" onClick={() => ToggleFilters("search")}>
            <label htmlFor="s">
              <img src={Search} alt="Search" />
              <span>Search</span>
            </label>
          </div>	 
		  {/*
          <div className="filter" onClick={() => ToggleFilters("filters")}>
            <label htmlFor="filter">
              <span>Filter</span>
              <img src={Filter} alt="Filter" />
            </label>
          </div>
		  */}
        </section>
        <div className="filters">
          {state.showSearch ? (
            <input
              type="text"
              name="s"
              placeholder="Search"
              onChange={FilterSearch}
              value={search}
            />
          ) : null}
		  
          {state.showFilter ? (
            <select name="category" onChange={FilterCategory} value={category}>
              <option value="0">Select Category</option>
              {props.data.allWpCategory.nodes &&
                props.data.allWpCategory.nodes.map((category, index) => {
                  return (
                    <option value={category.slug} key={index}>
                      {category.name}
                    </option>
                  )
                })}
            </select>
          ) : null}
        </div>
      </div>

      <div className="row post-list">
        <section className="posts" Style="padding: 0px 0;">
          <div className="container">
            <div className="row">
              {posts &&
                posts.map((post, index) => {
                  const hide = index + 1 > load.items ? "hide" : ""

                  return (
                    <div className={`col ${hide}`} key={`${index}-${post.id}`}>
                      <PostPreview
                        key={`post-${post.id}`}
                        post={post}
                        isLast={index === nodes.length - 1}
                      />
                    </div>
                  )
                })}
            </div>
          </div>

          {load.show || posts.length >= load.items ? (
            <div className="button-wrap">
              <button className="button" onClick={LoadMore}>
                Load More
              </button>
            </div>
          ) : null}
        </section>

        <Sidebar
          forms={props.data.allGfForm}
          options={acfOptionsOptions}
          categories={props.data.allWpCategory.nodes}
          handleSearch={FilterSearch.bind(this)}
          handleFilter={FilterCategory.bind(this)}
          query={search} 
		  Style="padding: 0px 0;"
        />
      </div>

      {/* <Instagram social={options.acfOptions.social} /> */}

      {popup ? (
        <div className="modal modal-popup">
          <div className="modal-outside" onClick={handlePopup}></div>
          <div className="modal-wrap">
            <button className="close" onClick={handlePopup}>
              x
            </button>

            <div className="content">
              <Form forms={props.data.allGfForm.nodes} upload="true" />
            </div>

          </div>
        </div>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        author: { node: { databaseId: { eq: $userDatabaseId } } }
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    wp {
      acfOptionsOptions {
        acfOptions {
          copy
          top {
            button
            buttonUrl
            content
            content2
            form
            title
            title2
          }
          bottom {
            button
            buttonUrl
            content
            content2
            content3
            title
            title2
            title3
            logo {
              sourceUrl
              altText
            }
            logo2 {
              sourceUrl
              altText
            }
          }
          social {
            icon {
              id
              sourceUrl
              altText
            }
            url
          }
          blogTitle
          blogContent
          gallery {
            altText
            mediaDetails {
              sizes {
                sourceUrl
              }
            }
            sourceUrl
          }
          supportContent
          supportTitle
          supportButton
          supportButtonUrl
          videos {
            videoUrl
          }
        }
      }
    }
    allWpCategory {
      nodes {
        id
        uri
        name
        slug
      }
    }
    wpPost(tags: { nodes: { elemMatch: { name: { eq: "featured" } } } }) {
      id
      databaseId
	  featuredImageId
      tags {
        nodes {
          name
        }
      }
      featuredImage {
        node {
		mediaItemUrl
		  sourceUrl
          localFile {
            childImageSharp {
              fluid {
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
                sizes
                aspectRatio
                presentationWidth
                src
                srcSet
              }
            }
          }
        }
      }
      date(formatString: "MMMM D, YYYY")
      categories {
        nodes {
          uri
          slug
          name
        }
      }
      author {
        node {
          uri
          name
        }
      }
      title
      excerpt
      uri
    }
    allGfForm {
      nodes {
        apiURL
        formId
        formFields {
          id
          placeholder
          label
          type
          inputName
          content
          choices
          isRequired
        }
        button {
          imageUrl
          text
          type
        }
        confirmations {
          message
        }
        is_active
        slug
      }
    }
  }
`

export default Archive
